<template>
    <div class="value-display" :class="breakeven_status">
        <i v-if="icon" :class="icon" />
        <span class="value" :class="{bold}">{{ value | currency }}</span>
    </div>
</template>

<script>
export default {
    name: 'breakeven-value',
    props: {
        breakeven: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        bold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        breakeven_status() {
            return this.value >= this.breakeven ? 'above' : 'below';
        },
        icon() {
            if (this.value > this.breakeven) {
                return 'icon el-icon-arrow-up';
            }
            if (this.value < this.breakeven) {
                return 'icon el-icon-arrow-down';
            }
            return null;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.value-display {
    padding: 10px;
    border-radius: 4px;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
        flex: 0;
    }
    .value {
        flex: 1;
        text-align: right;
        &.bold {
            font-weight: bold;
        }
    }

    &.above {
        background-color: rgba($green-dark, 0.2);
        .icon {
            color: $green-dark;
        }
    }
    &.below {
        background-color: rgba($red-soft, 0.1);
        .icon {
            color: $red;
        }
    }
}
</style>

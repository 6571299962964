import request from '@/utils/request';

// {{host}}/
export function getCashflowReport(fy) {
    return request({
        url: '/report/cashflow',
        method: 'GET',
        params: {fy},
    });
}

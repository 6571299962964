<template>
    <div>
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Cashflow" name="_cashflow" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <ReportTab_cashflow
                v-if="activeTab == '_cashflow'"
                key="cashflow"
            />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import ReportTab_cashflow from '@/pages/reports/tabs/ReportTab_cashflow';

export default {
    name: 'component-detail',
    components: {
        ActionsButtons,
        ReportTab_cashflow,
    },
    data() {
        return {
            activeTab: '_cashflow',
        };
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    methods: {
        tabChanged(event) {
            this.$router.replace({
                name: `report${event.name}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
